// import { allapiAction } from "@/Redux/common/action";
import { UsergroupDeleteOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
// import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allapiAction } from "../../../Redux/common/action";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";

const Boxes25 = () => {
  const navigate = useNavigate();
  const location = navigate;
  const dispatch = useDispatch();
  const deshboard = useSelector((state) =>
    state?.allapi?.deshboard ? state?.allapi?.deshboard : {}
  );

  const get_account_payment = useSelector((state) =>
    state?.allapi?.get_account_payment ? state?.allapi?.get_account_payment : {}
  );

  const get_profile = useSelector((state) =>
    state?.allapi?.get_profile ? state?.allapi?.get_profile : {}
  );

  console.log(get_profile);

  useEffect(() => {
    // if (search) {
    //   dispatch(allapiAction.getSearchOrdervendor({ page: page, key: search }));
    // } else {

    // dispatch(allapiAction.vendorpaybleamdall());
    // }
    // dispatch(allapiAction.getInvoceDetails());
    return () => {};
  }, [location]);

  const [amount, setamount] = useState({});

  const hendleTochangeWthDraw = (e) => {
    const amount = e.target.value;

    const admincommission = (amount * 15) / 100;

    setamount({ ...amount, amount: amount, admincommission: admincommission });
  };

  const submitProductWithdrawal = async () => {
    // const data = new FormData();
    // if (images[0]?.file) {
    // roledata?.role_name
    // data.append("logo", images[0]?.file);

    if (!amount?.amount) {
      toast.error("please enter Amount");
    } else {
      const response = await dispatch(
        allapiAction.Withdrawalsrequset({ amount: amount?.amount })
      );

      if (response?.success) {
        setamount({ amount: "", admincommission: "" });

        dispatch(allapiAction.getprofile());
      }
    }
  };
  return (
    <Row gutter={[0, 20]}>
      <Col lg={24} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              Wallet {/* Pending Withdraw */}
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText"> {get_profile?.earningbalance}</div>
            {/* <UsergroupDeleteOutlined /> */}
          </div>
        </div>
      </Col>
      <Col lg={24} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
        >
          <div className="row">
            <div className="col-12 col-md-4">
              <label>Amount</label>
            </div>
            <div className="col-12 col-md-8">
              <input
                type="text"
                className="form-control"
                value={amount?.amount}
                onChange={(e) => {
                  hendleTochangeWthDraw(e);
                }}
              />
            </div>
            <div className="col-12 col-md-4 mt-5">
              <label style={{ color: "red" }}>
                15% Admin charge will be applied
              </label>
            </div>
            <div className="col-12 col-md-8  mt-5">
              <input
                type="text"
                value={amount?.admincommission}
                className="form-control"
              />
            </div>
            <div className="col-12  mt-5">
              <button
                className="btn btn-success w-100"
                onClick={() => {
                  submitProductWithdrawal();
                }}
              >
                Withdrawal
              </button>{" "}
            </div>
            <div className="col-12 mt-5">
              <button className="btn btn-danger w-100"> Cancel</button>{" "}
            </div>
          </div>
        </div>
      </Col>
      {/* <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          // onClick={() => navigate("/users?page=1&status=0")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              Aready Withdrawn
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText">
              {get_account_payment?.vendorWithdrawalstotalAmount}
            </div>
          </div>
        </div>
      </Col> */}

      {/* <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              Today Company Busi.
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText">{deshboard?.companyBusiness}</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">
              Today Comp. Busi. With GST
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText color-risk">
              {deshboard?.companyBusinessWithGst}
            </div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/category")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              Total Stock Amount
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText">
              {deshboard?.totalStockProductAmount}
            </div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          onClick={() => navigate("/gpg-ct")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">Gpg ct</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText color-risk">{deshboard?.gpgct}</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          onClick={() => navigate("/gpg-mq")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">Gpg mq</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText">{deshboard?.gpgmq}</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          onClick={() => navigate("/gpg-duqe")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">Gpg duqe</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText color-risk">{deshboard?.gpgduqe}</div>
          </div>
        </div>
      </Col> */}
      {/* <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
        //   onClick={() => navigate("/category")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              Total admin commission
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText">{deshboard?.adminWinCommission}</div>
          </div>
        </div>
      </Col> */}

      {/* <Col span={6}>
        <div
          className="flex1 p-10 dashboardBox cursor-pointer"
          onClick={() => navigate("/question")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              Total Question
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText">{deshboard?.total_question || 0}</div>
          </div>
        </div>
      </Col> */}
    </Row>
  );
};

export default Boxes25;
